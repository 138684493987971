<template>
  <v-container fluid class="pa-0 secondary" dark>
    <v-row class="pa-0">
      <v-col class="pt-0 text-center white--text">
        <v-card
          flat
          color="transparent"
          :href="urlGuiaJornada"
          target="_blank"
        >
          <v-img
            :src="getImg(imagem)"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  components: {
  },
  //props: ['endtime'],
  data: () => ({
    urlGuiaJornada: 'https://drive.google.com/file/d/1wdbzWaY8rN3JPeIM70SqcstHXBm-1zUU/view?usp=sharing',
    imagem: 'cta-guia.jpg'
  }),
  methods: {
    getImg(i) {
      return require('../assets/img/'+i)
    },
  }
}
</script>
