<template>
  <v-container fluid class="py-16 grey lighten-2" light>

    <v-row class="pt-12 justify-center">
      <v-col class="text-center">
        <div class="text-h6">
          <span class="poppins">Veja o que</span>
        </div>
        <div class="pt-3 text-h3">
          <span class="poppins font-weight-semibold text-uppercase">Você vai receber</span>
        </div>
      </v-col>
    </v-row>

    <v-row 
      class="py-12 px-6 justify-center white--text"
    >
      <v-col 
        cols="12" md="8"
        class="text-center"
      >
        <v-row>
          <v-col
            cols="6" md="4"
            v-for="(item, i) in itens"
            :key="i"
            class="pb-12"
          >
            <v-avatar size="122" class="black">
              <v-icon size="60" color="primary">{{ item.icone }}</v-icon>
            </v-avatar>
            <div class="pt-2 text-h5">
              <span class="raleway black--text font-weight-medium">{{ item.titulo }}</span>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<style >

</style>

<script>

export default {

  data: () => ({
    itens: [
      {
        icone: 'mdi-play',
        titulo: '5 Aulas'
      }, 
      {
        icone: 'mdi-notebook-outline',
        titulo: 'Material Didático'
      }, 
      // {
      //   icone: 'mdi-book-open-outline',
      //   titulo: 'E-Book'
      // }, 
      // {
      //   icone: 'mdi-certificate',
      //   titulo: 'Certificado'
      // }, 
      {
        icone: 'mdi-table',
        titulo: 'Ferramentas'
      }, 
      // {
      //   icone: 'mdi-gift',
      //   titulo: 'Prêmios'
      // }, 
    ],
  })
}
</script>
