<template>
  <v-row class="justify-space-between">
    <v-col cols="12" class="pb-0">
      <div class="pt-3 text-h6">
        <span class="poppins text-uppercase">A semana começará em</span>
      </div>
    </v-col>
    <v-col cols="12" class="pt-4">
      <v-card flat tile color="transparent" class="">
        <v-card-text class="d-flex justify-center px-0">

          <v-card flat color="transparent" class="text-center" width="90">
            <v-btn small text class="text-h3 px-0 mx-0" height="50" width="60">
              <span class="poppins white--text font-weight-semibold">{{days}}</span>
            </v-btn>
            <div class="text-subtitle-2 d-block">
              <span class="raleway primary--text font-weight-medium">dias</span>
            </div>
          </v-card>

          <div class="px-2 text-h5" style="heigh: 50px; line-height: 50px">
            <span class="poppins grey--text font-weight-black"> </span>
          </div>

          <v-card flat color="transparent" class="text-center" width="90">
            <v-btn small text class="text-h3 px-0 mx-0" height="50" width="60">
              <span class="poppins white--text font-weight-semibold">{{hours}}</span>
            </v-btn>
            <div class="text-subtitle-2 d-block">
              <span class="raleway primary--text font-weight-medium">horas</span>
            </div>
          </v-card>

          <div class="px-2 text-h5" style="heigh: 50px; line-height: 50px">
            <span class="poppins grey--text font-weight-bold"> </span>
          </div>

          <v-card flat color="transparent" class="text-center" width="90">
            <v-btn small text class="text-h3 px-0 mx-0" height="50" width="60">
              <span class="poppins white--text font-weight-semibold">{{minutes}}</span>
            </v-btn>
            <div class="text-subtitle-2 d-block">
              <span class="raleway primary--text font-weight-medium">minutos</span>
            </div>
          </v-card>

          <div class="px-2 text-h5" style="heigh: 50px; line-height: 50px">
            <span class="poppins grey--text font-weight-black"> </span>
          </div>

          <v-card flat color="transparent" class="text-center" width="90">
            <v-btn small text class="text-h3 px-0 mx-0" height="50">
              <span class="poppins white--text font-weight-semibold">{{seconds}}</span>
            </v-btn>
            <div class="text-subtitle-2 d-block">
              <span class="raleway primary--text font-weight-medium">segundos</span>
            </div>
          </v-card>

        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  //props: ['endtime'],
  data: () => ({
    starttime: '',
    endtime: 'Jun 27, 2022 20:00:00',
    start: '',
    end: '',
    interval: '',
    days: '',
    minutes: '',
    hours: '',
    seconds: ''
  }),
  methods: {
    timerCount (start,end) {
      var now = new Date().getTime()
      //var distance = start - now
      var passTime = end - now
      
      if(passTime < 0) {
        clearInterval(this.interval)
        this.days = 0
        this.hours = 0
        this.minutes = 0
        this.seconds = 0
      }
      else {
        this.calcTime(passTime)
      }

      //console.log('DISTANCE',distance)
      //console.log('PASSTIME',passTime)
    },
    calcTime: function(dist){
      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(dist / (1000 * 60 * 60 * 24))
      this.hours = Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60))
      this.seconds = Math.floor((dist % (1000 * 60)) / 1000)
    }
  },
  mounted () {
    this.start = new Date(this.starttime).getTime()
    this.end = new Date(this.endtime).getTime()
    this.timerCount(this.start, this.end)

    // LOOP
    this.interval = setInterval(() => {
      this.timerCount(this.start,this.end);
    }, 1000);
  }
}
</script>