<template>
  <!-- <v-app-bar
    app flat dark absolute
    color="black"
    height="140"
  >
    <div class="pt-0 mx-auto">
      <v-img
        alt="Vuetify Logo"
        class="mx-auto"
        contain
        :src="getImg(logo)"
        transition="scale-transition"
        width="270"
      />
    </div>
  </v-app-bar> -->
   <v-app-bar
    app flat dark absolute
    color="black"
    height="120"
    class="px-9"
  >
    <div class="pt-0">
      <v-img
        class=""
        contain
        :src="getImg(logo)"
        transition="scale-transition"
        width="220"
      />
    </div>
    <v-spacer></v-spacer>
    <!-- <v-btn outlined color="primary" class="">
      <span>VOUCHERS DE PARCEIROS</span>
    </v-btn> -->
    <v-btn 
      color="primary black--text" 
      class="ml-2"
      @click="$vuetify.goTo('#inscricao', { offset: 0 })"
    >
      <span>INSCREVA-SE</span>
    </v-btn>
  </v-app-bar>
</template>

<script>
  export default {
    name: 'Navbar',

    data: () => ({
      logo: 'logo-egg-r5a.png', 
    }),

    methods: {
      getImg(i) {
        return require('@/assets/img/'+i)
      },
    },
  }
</script>
