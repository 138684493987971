<template>
  <v-container fluid class="py-16 grey darken-4" dark>

    <v-row class="pt-12 justify-center">
      <v-col class="pb-0 text-center">
        <div class="text-h4">
          <span class="poppins white--text font-weight-semibold text-uppercase">Programação do Curso</span>
        </div>
        <div class="pt-2 text-h6">
          <span class="poppins grey--text font-weight-regular"> <span class="primary--text">100% gratuito</span> e <span class="primary--text">100% online</span></span>
        </div>
      </v-col>
    </v-row>

    <v-row class="pt-12 justify-center">
      <v-col cols="12" md="10" class="pt-3 white--text"
        :class="{
          'px-6': $vuetify.breakpoint.smAndDown,
          'px-12': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <v-tabs
          dark icons-and-text
          v-model="tab"
          background-color="transparent"
          color="primary"
          class="px-1"
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab 
            v-for="(p,i) in programacao"
            :key="i"
            :href="'#tab-' + i" 
            class="mx-2"
          >
            <div class="text-h5">
              <span class="white--text font-weight-bold text-capitalize" v-html="p.titulo"></span>
            </div>
            <div class="text-caption">
              <span class="primary--text font-weight-medium">{{ p.caption }}</span>
            </div>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-model="tab"
          class="transparent pl-0 ml-0"
          :class="{
            '' : $vuetify.breakpoint.smAndDown,
            '' : $vuetify.breakpoint.mdAndUp,
          }"
        >
          <v-tab-item
            v-for="(p,i) in programacao"
            :key="i"
            :value="'tab-' + i"
            class="pt-9"
            :class="{
              '' : $vuetify.breakpoint.smAndDown,
              '' : $vuetify.breakpoint.mdAndUp,
            }"
          >
            <div 
              v-for="(assunto,j) in p.agenda"
              :key="j"
              class="pb-1"
            >
              
              <!-- <div class="d-flex pb-0">
                <div class="px-3 pt-1">
                  <v-icon medium color="primary">mdi-clock-outline</v-icon>
                </div>
                <div
                  :class="{
                    'text-h6' : $vuetify.breakpoint.smAndDown,
                    'text-h5' : $vuetify.breakpoint.mdAndUp,
                  }"
                >
                  <span class="font-weight-regular white--text" v-html="assunto.horario"></span>
                </div>
              </div> -->

              <div class="d-flex">
                <div class="px-3 pt-1 pb-0">
                  <v-chip class="grey darken-2 primary--text text-h6" @click="$vuetify.goTo('#inscricao', { offset: 0 })">
                    <v-icon medium color="primary" class="font-weight-bold">{{ assunto.icone }}</v-icon>
                    <!-- <span class="poppins pl-1 font-weight-medium" v-html="assunto.horario"></span> -->
                  </v-chip>
                </div>
                <div class="d-flex pl-1 pt-1" style="line-height: 2">
                  <!-- <div class="pr-2">
                    <v-icon medium color="primary">{{ assunto.icone }}</v-icon>
                  </div> -->
                  <div
                    :class="{
                      'text-h6' : $vuetify.breakpoint.smAndDown,
                      'text-h5' : $vuetify.breakpoint.mdAndUp,
                    }"
                  >
                    <span class="font-weight-regular white--text" v-html="assunto.tema"></span>
                  </div>
                </div>
              </div>
            </div>
            
            <!-- <div class="pt-9 d-flex">
              <v-avatar
                v-if="p.foto"
                size="70" class="ml-3" border="1"
              >
                <v-img
                  :src="getImg(p.foto)"
                />
              </v-avatar>
              <div class="pl-4">
                <div class="text-caption">
                  <span class="poppins text-uppercase font-weight-medium">{{ p.rotuloConvidado }}</span>
                </div>
                <div class="text-h5">
                  <span class="raleway primary--text font-weight-bold">{{ p.convidado }}</span>
                </div>
              </div>
            </div> -->
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-row class="pt-12 pb-12 justify-center">
      <v-col
        cols="12" md="10"
        class="text-center"
        :class="{
          'px-6': $vuetify.breakpoint.smAndDown,
          'px-12': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <div>
          <v-btn 
            block
            rounded
            x-large
            outlined
            height="70"
            color="primary"
            class="px-6 black--text text-h5"
            style="border-radius: 50px !important; border: 2px solid"
            @click="$vuetify.goTo('#inscricao', { offset: 0 })"
          >
            <span class="font-weight-medium">Fazer Inscrição</span>
          </v-btn>
        </div>
        <div class="text-caption pt-2">
          <span class="poppins white--text font-weight-medium">É 100% gratuito!</span>  
        </div>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

export default {
  data: () => ({
    tab: 'tab-0',
    programacao: [
      {
        //caption: 'Segunda-feira',
        titulo: '1º Módulo',
        rotuloConvidado: 'Convidado Especial',
        convidado: 'Olivier Anquier',
        foto: 'olivier-anquier.jpg',
        agenda: [
          {
            icone: 'mdi-play',
            // tema: '<span class="white--text font-weight-medium">Tema:</span> O que é ser um Restaurateur',
            tema: 'O que é ser um Restaurateur',
            // horario: '<span class="white--text font-weight-medium">Horário:</span> 20h',
            //horario: '20h00',
          },
          // {
          //   icone: 'mdi-star',
          //   tema: 'O que é a Nova Gastronomia?'
          // },
          // {
          //   icone: 'mdi-star',
          //   tema: 'Por que ser Restaurateur?'
          // },
          // {
          //   icone: 'mdi-star',
          //   tema: 'Casos de Sucesso'
          // },
          // {
          //   icone: 'mdi-star',
          //   tema: 'Desafios no Brasil'
          // },
        ]
      },
      {
        //caption: 'Terça-feira',
        titulo: '2º Módulo',
        // rotuloConvidado: 'Convidados Especiais',
        // convidado: 'Fabricio Lemos, Ju Tricate, Bruno Hoffman',
        foto: '',
        agenda: [
          // {
          //   icone: 'mdi-trophy',
          //   tema: '<span class="white--text font-weight-medium">Premiação:</span> Prêmio EGG Restaurateur do Ano',
          // },
          {
            icone: 'mdi-play',
            tema: 'Dia a dia de um Restaurateur',
            //horario: '09h00',
          },
          {
            icone: 'mdi-play',
            tema: 'A importância de qualificar a informação: como o sistema pode ajudar',
            //horario: '20h00',
          },
          // {
          //   icone: 'mdi-star',
          //   tema: 'Como expandir sem mão de obra qualificada'
          // }
        ]
      },
      {
        //caption: 'Quarta-feira',
        titulo: '3º Módulo',
        //horario: '20h',
        convidado: '',
        foto: '',
        agenda: [
          {
            icone: 'mdi-play',
            tema: 'Habilidades de um Restaurateur: agilidade em adaptar as mudanças no digital',
            //horario: '09h00',
            convidados: 'Nathalia Sifuentes, Paulo Bitelman, André Mortari',
          },
          {
            icone: 'mdi-play',
            tema: 'Experiência do cliente através da tecnologia',
            //horario: '15h00',
            convidados: 'Get In'
          },
          {
            icone: 'mdi-star',
            tema: '<span class="primary--text">Masterclass 1:</span> Como nascem os conceitos e as oportunidades',
            //horario: '19h00',
            convidados: 'Priscila Nonaka'
          },
          {
            icone: 'mdi-star',
            tema: '<span class="primary--text">Masterclass 2:</span> Primeiros passos de um Restaurateur: de profissional da gastronomia a dono de restaurante',
            //horario: '19h30',
            convidados: 'Ciro Aidar'
          },
        ]
      },
      {
        //caption: 'Quinta-feira',
        titulo: '4º Módulo',
        convidado: '',
        foto: '',
        agenda: [
          {
            icone: 'mdi-play',
            tema: 'Como abrir diversos de restaurantes, aplicar o conceito e não estourar o orçamento',
            //horario: '09h00',
            convidados: ''
          },
          {
            icone: 'mdi-play',
            tema: 'Como a tecnologia otimiza os custos da operação',
            //horario: '15h00',
            rotuloConvidado: 'Convidados',
            convidados: 'Topema: João Vitor e Caroline Lofit'
          },
          {
            icone: 'mdi-star',
            tema: '<span class="primary--text">Masterclass 3:</span> Habilidades de um Restaurateur para reter talentos: soft skills',
            //horario: '19h00',
            rotuloConvidado: 'Professora',
            convidados: 'Andreia Ceneviva'
          },
          {
            icone: 'mdi-star',
            tema: '<span class="primary--text">Masterclass 4:</span> Planejamento e análise financeira para o crescimento do negócio',
            //horario: '19h30',
            rotuloConvidado: 'Professor',
            convidados: 'Rubens Oliveira'
          },
        ]
      },
      {
        //caption: 'Sexta-feira',
        titulo: '5º Módulo',
        convidado: '',
        foto: '',
        agenda: [
          {
            icone: 'mdi-play',
            tema: 'Mostrar a importância da gestão também nas cozinhas e se existe restaurateur nas cozinhas',
            //horario: '09h00',
            rotuloConvidado: 'Convidados',
            convidados: 'Marcus Livi, Rodrigo Martins'
          },
          {
            icone: 'mdi-play',
            tema: 'Decisão estratégica através da qualidade da informação',
            //horario: '15h00',
            rotuloConvidado: 'Convidado',
            convidados: 'Altec: Nelson Millner'
          },
          {
            icone: 'mdi-star-shooting',
            tema: '<span class="primary--text">Super Masterclass:</span> O que realmente faz a diferença no sucesso do Restaurateur',
            //horario: '19h00',
            rotuloConvidado: 'Professores',
            convidados: 'Marcelo Traldi, Monica Rangel, Paulo Neto'
          },
        ]
      },
    ]
  }),

  methods: {
    getImg(i) {
      return require('../assets/img/palestrantes/'+i)
    },
  },
}
</script>
