<template>
  <v-container fluid class="pt-9 pb-9 grey lighten-3" dark>

    <v-row class="pt-16 pb-0 justify-center">
      <v-col class="pb-0 text-center">
        <div class="text-h6">
          <span class="poppins grey--text text--darken-3 font-weight-medium">Nossos</span>
        </div>
        <div class="pt-1 text-h4">
          <span class="poppins black--text text--darken-3 font-weight-semibold text-uppercase">Apoiadores</span>
        </div>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="12" md="9" class="pb-12">
        <v-row class="px-6 py-0 justify-center">
          <v-col v-for="(d, i) in destaques" :key="i" md="4" cols="12" class="py-0">
            <v-card
              flat
              class="py-0 transparent text-center"
              :href="d.link"
              target="_blank"
            >
              <v-img
                contain
                class="mx-auto"
                :src="getImg(d.logo)"
                width="300"
              />
            </v-card>
          </v-col>
        </v-row>
        <v-row class="px-12 pt-3 pb-12 justify-center">
          <v-col
            cols="6" md="3"
            v-for="(a, i) in apoiadores" :key="i"
            class="pt-0 text-center white--text"
          >
            <v-card
              flat
              class="py-0 transparent"
              :href="a.link"
              target="_blank"
            >
              <v-img
                contain
                :src="getImg(a.logo)"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>

export default {
  data: () => ({
    destaques: [
      { 
        logo: 'prazeres_da_mesa.png',
        link: 'https://www.prazeresdamesa.com.br/?utm_campaign=egg-r5a-curso-gratuito&utm_source=egg-r5a-lp-curso-gratuito&utm_medium=form-inscricao-curso-gratuito'
      },
      { 
        logo: 'mundo_mesa.png',
        link: 'https://www.prazeresdamesa.com.br/mundo-mesa/?utm_campaign=egg-r5a-curso-gratuito&utm_source=egg-r5a-lp-curso-gratuito&utm_medium=form-inscricao-curso-gratuito'
      },
      { 
        logo: 'melhores_do_ano.png',
        link: 'https://www.prazeresdamesa.com.br/melhores-do-ano/?utm_campaign=egg-r5a-curso-gratuito&utm_source=egg-r5a-lp-curso-gratuito&utm_medium=form-inscricao-curso-gratuito'
      }
    ],
    apoiadores: [
      {
        logo: 'apoiador_abrasel.png',
        link: 'https://abrasel.com.br/?utm_campaign=egg-r5a-curso-gratuito&utm_source=egg-r5a-lp-curso-gratuito&utm_medium=form-inscricao-curso-gratuito'
      },
      {
        logo: 'apoiador_sindrio.png',
        link: 'https://www.sindrio.com.br/?utm_campaign=egg-r5a-curso-gratuito&utm_source=egg-r5a-lp-curso-gratuito&utm_medium=form-inscricao-curso-gratuito'
      },
      {
        logo: 'apoiador_anr.png',
        link: 'https://anrbrasil.org.br/?utm_campaign=egg-r5a-curso-gratuito&utm_source=egg-r5a-lp-curso-gratuito&utm_medium=form-inscricao-curso-gratuito'
      },
      {
        logo: 'apoiador_galunion.png',
        link: 'https://www.galunion.com.br/?utm_campaign=egg-r5a-curso-gratuito&utm_source=egg-r5a-lp-curso-gratuito&utm_medium=form-inscricao-curso-gratuito'
      },
      {
        logo: 'apoiador_nexxo.png',
        link: 'https://nexxoempresarial.com.br/?utm_campaign=egg-r5a-curso-gratuito&utm_source=egg-r5a-lp-curso-gratuito&utm_medium=form-inscricao-curso-gratuito'
      },
      {
        logo: 'apoiador_accor.png',
        link: 'https://all.accor.com/brasil/index.pt-br.shtml/?utm_campaign=egg-r5a-curso-gratuito&utm_source=egg-r5a-lp-curso-gratuito&utm_medium=form-inscricao-curso-gratuito'
      },
      {
        logo: 'apoiador_foodco.png',
        link: 'https://foodco.com.br/?utm_campaign=egg-r5a-curso-gratuito&utm_source=egg-r5a-lp-curso-gratuito&utm_medium=form-inscricao-curso-gratuito'
      },
    ]
  }),

  methods: {
    getImg(i) {
      return require('../assets/img/parceiros/'+i)
    },
  },
}
</script>
