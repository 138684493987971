var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"py-16 grey darken-4",attrs:{"fluid":"","dark":""}},[_c('v-row',{staticClass:"pt-12 justify-center"},[_c('v-col',{staticClass:"pb-0 text-center"},[_c('div',{staticClass:"text-h4"},[_c('span',{staticClass:"poppins white--text font-weight-semibold text-uppercase"},[_vm._v("Programação do Curso")])]),_c('div',{staticClass:"pt-2 text-h6"},[_c('span',{staticClass:"poppins grey--text font-weight-regular"},[_c('span',{staticClass:"primary--text"},[_vm._v("100% gratuito")]),_vm._v(" e "),_c('span',{staticClass:"primary--text"},[_vm._v("100% online")])])])])],1),_c('v-row',{staticClass:"pt-12 justify-center"},[_c('v-col',{staticClass:"pt-3 white--text",class:{
          'px-6': _vm.$vuetify.breakpoint.smAndDown,
          'px-12': _vm.$vuetify.breakpoint.mdAndUp,
        },attrs:{"cols":"12","md":"10"}},[_c('v-tabs',{staticClass:"px-1",attrs:{"dark":"","icons-and-text":"","background-color":"transparent","color":"primary"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_vm._l((_vm.programacao),function(p,i){return _c('v-tab',{key:i,staticClass:"mx-2",attrs:{"href":'#tab-' + i}},[_c('div',{staticClass:"text-h5"},[_c('span',{staticClass:"white--text font-weight-bold text-capitalize",domProps:{"innerHTML":_vm._s(p.titulo)}})]),_c('div',{staticClass:"text-caption"},[_c('span',{staticClass:"primary--text font-weight-medium"},[_vm._v(_vm._s(p.caption))])])])})],2),_c('v-tabs-items',{staticClass:"transparent pl-0 ml-0",class:{
            '' : _vm.$vuetify.breakpoint.smAndDown,
            '' : _vm.$vuetify.breakpoint.mdAndUp,
          },model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.programacao),function(p,i){return _c('v-tab-item',{key:i,staticClass:"pt-9",class:{
              '' : _vm.$vuetify.breakpoint.smAndDown,
              '' : _vm.$vuetify.breakpoint.mdAndUp,
            },attrs:{"value":'tab-' + i}},_vm._l((p.agenda),function(assunto,j){return _c('div',{key:j,staticClass:"pb-1"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"px-3 pt-1 pb-0"},[_c('v-chip',{staticClass:"grey darken-2 primary--text text-h6",on:{"click":function($event){return _vm.$vuetify.goTo('#inscricao', { offset: 0 })}}},[_c('v-icon',{staticClass:"font-weight-bold",attrs:{"medium":"","color":"primary"}},[_vm._v(_vm._s(assunto.icone))])],1)],1),_c('div',{staticClass:"d-flex pl-1 pt-1",staticStyle:{"line-height":"2"}},[_c('div',{class:{
                      'text-h6' : _vm.$vuetify.breakpoint.smAndDown,
                      'text-h5' : _vm.$vuetify.breakpoint.mdAndUp,
                    }},[_c('span',{staticClass:"font-weight-regular white--text",domProps:{"innerHTML":_vm._s(assunto.tema)}})])])])])}),0)}),1)],1)],1),_c('v-row',{staticClass:"pt-12 pb-12 justify-center"},[_c('v-col',{staticClass:"text-center",class:{
          'px-6': _vm.$vuetify.breakpoint.smAndDown,
          'px-12': _vm.$vuetify.breakpoint.mdAndUp,
        },attrs:{"cols":"12","md":"10"}},[_c('div',[_c('v-btn',{staticClass:"px-6 black--text text-h5",staticStyle:{"border-radius":"50px !important","border":"2px solid"},attrs:{"block":"","rounded":"","x-large":"","outlined":"","height":"70","color":"primary"},on:{"click":function($event){return _vm.$vuetify.goTo('#inscricao', { offset: 0 })}}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Fazer Inscrição")])])],1),_c('div',{staticClass:"text-caption pt-2"},[_c('span',{staticClass:"poppins white--text font-weight-medium"},[_vm._v("É 100% gratuito!")])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }