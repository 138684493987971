<template>
  <v-container fluid black darken-4 class="py-0">

    <v-row class="py-6 justify-center">
      <v-col class="text-center">
        <div class="text-caption">
          <div class="pr-1 poppins grey--text">
            <span>© EGG – Negócios da Gastronomia. Todos os direitos reservados. CNPJ: 30909393000111</span>
          </div>
          <div class="pr-1 poppins grey--text">
            <span>Rua Henrique Monteiro, 121, Pinheiros, São Paulo-SP</span>
          </div>
          <div>
            <a @click.stop="dialog = true"><span class="poppins primary--text">Política de Privacidade</span>.</a>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-footer
      v-if="gdpr"
      fixed padless dark
      min-height="275"
      color="rgba(0,0,0,0.85)"
      class="justify-center d-flex align-center"
    >
      <v-card
        flat tile
        max-width="600"
        class="ma-2 transparent text-center align-center"
      >
        <v-card-text class="mb-0 text-subtitle-1">
          <span class="pr-1 poppins white--text">Utilizamos cookies para garantir a você a melhor experiência no nosso site. Fechando essa mensagem, você concorda com nossa utilização de cookies.</span>
          <a @click.stop="dialog = true"><span class="poppins primary--text">Política de Privacidade</span>.</a>
        </v-card-text>
        <v-card-text>
          <v-btn
            large tile outlined
            class="mx-4 primary--text"
            @click="gdpr = !gdpr" 
          >
            <span class="font-weight-bold">Eu Concordo</span>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-footer>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Política de Privacidade
        </v-card-title>

        <v-card-text class="pt-6">
          <p
            v-for="(t,i) in texto" :key="i"
            v-html="t"
          ></p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            @click="dialog = false"
          >
            Eu aceito
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
export default {

  data: () => ({
    dialog: false,
    gdpr: true,
    texto: [
      '1. INFORMAÇÕES GERAIS',
      'A EGG - Escola de Gestão em Negócios da Gastronomia Ltda. tem o compromisso com a transparência, a privacidade e a segurança dos dados de seus clientes, pessoas interessadas em receber informativos e público de nossos eventos.',
      'A presente Política de Privacidade contém informações a respeito do modo como tratamos os dados pessoais dos usuários que acessam nossos sites (eggeduca.com, negociosdagastronomia.com.br e sourestaurateur.com.br) e nossa plataforma de ensino.',
      'Nosso objetivo é esclarecer de forma clara e transparente a todos que possuem algum tipo de relacionamento com a nossa empresa acerca dos tipos de dados que são coletados, dos motivos da coleta, garantindo a segurança de seus dados.',
      'Ao navegar em nossos sites e/ou se comunicar conosco, você concorda com o tratamento de suas informações.',
      'No caso específico da campanha “Restaurateur 5 Anos”, que promoverá uma série de eventos até o fim de 2022, você concorda que os patrocinadores também tenham acesso, de forma responsável, aos seus dados, com o objetivo de apresentar suas soluções por meio de campanhas de marketing digital.',
      '2. INFORMAÇÕES COLETADAS E SUA DESTINAÇÃO',
      'Todas as informações pessoais colhidas são destinadas para cadastro, liberação de acesso à Semana Restaurateur 5 Anos (27 de Junho a 1 de Julho de 2022), para informar sobre a programação do evento e manter relacionamento por meio de ações de marketing digital através das empresas que compõem o Grupo EGG e das empresas patrocinadoras da campanha Restaurateur 5 Anos.',
      '3. AGENTES DE TRATAMENTO DE DADOS',
      'Além do Grupo EGG, serão controladoras e operadoras de todo e qualquer dado colhido através do site e plataforma as seguintes empresas patrocinadoras da campanha Restaurateur 5 Anos:',
      'GET IN TECNOLOGIA SA - CNPJ sob o nº 20.093.230/0001-50',
      'TOPEMA - CNPJ: 60.726.403/0001-44',
      'LETS DELIVERY - LD GESTÃO EM CONSULTORIA EMPRESARIAL LTDA - CNPJ: 13.619.615/0001-42',
      'SPICE - ALTERDATA TECNOLOGIA EM INFORMATICA LTDA - CNPJ 36.462.778/0001-60',
      'NEOGOURMET ARQUITETURA LTDA - CNPJ 31.739.988/0001-39',
      'ALTEC SISTEMAS E TECNOLOGIA - CNPJ 06.142.226/0001-03',
      '4. DADOS SENSÍVEIS PESSOAIS',
      'Na EGG não tratamos/solicitamos dados sensíveis pessoais, sendo eles: (i) dados pessoais que revelem a origem racial ou étnica; (ii) opiniões políticas; (iii) convicções religiosas ou filosóficas; (iv) filiação sindical; (v) dados genéticos ou biométricos; (vi) dados relacionados com a saúde; (vii) dados relativos à vida sexual ou orientação sexual.',
      '5. SEUS DIREITOS',
      'A Lei Geral de Proteção de Dados (LGPD) dá ao Cliente o direito de solicitar à EGG, a qualquer momento, mediante requisição formal, informações referentes aos dados tratados.',
      'Para que a Política de Privacidade seja bem compreendida, é fundamental esclarecer alguns conceitos importantes:',
      '• Cliente – toda pessoa física que adquire produtos ou serviços;',
      '• Dados pessoais – qualquer informação relacionada a uma pessoa que a identifique ou que, usada em combinação com outras informações tratadas, identifique um indivíduo. Ainda, qualquer informação por meio da qual seja possível identificar uma pessoa ou entrar em contato com ela.',
      '• Tratamento de dados pessoais – considera-se tratamento de dado pessoal a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, comunicação, transferência, difusão ou extração de dados de pessoas físicas.',
      '• Titular dados – qualquer pessoa física que tenha seus dados pessoais tratados pela EGG;',
      '• Finalidade – o que queremos alcançar com o tratamento de dados pessoais.',
      '• Necessidade – o tratamento de dados pessoais deve se limitar ao mínimo necessário para o propósito almejado. Ou seja, deve ser pertinente, proporcional e não excessivo.',
      '• Consentimento – autorização clara e objetiva que o titular dá para tratamento de seus dados pessoais com finalidade previamente estipulada. Após dar o consentimento, você pode revogá-lo a qualquer momento. A revogação não cancela os processamentos realizados previamente.',
      '• A EGG – Escola de Gestão em Negócios da Gastronomia Ltda. é composta pelas empresas listadas abaixo:',
      'EGG- ESCOLA DE GESTÃO EM NEGÓCIOS DA GASTRONOMIA LTDA. – CNPJ 30.909.393/0001-11',
      'EGG LAB TREINAMENTOS LTDA – CNPJ 39.768.962/0001-20',
      'EXPLICAFOOD – CNPJ 30.231.673/0001-13',
      'TUORLO PARTICIPACOES LTDA – CNPJ 38.050283/0001-59',
      'DLA PARTICIPACOES LTDA – CNPJ 37.853.949/0001-44',
      '6. COM QUEM COMPARTILHAMOS SEUS DADOS?',
      'As informações coletadas somente serão compartilhadas quando forem necessárias: (i) para prestação adequada dos serviços contratados; (ii) mediante decisão judicial ou requisição de autoridade competente; (iii) com empresas provedoras de infraestrutura tecnológica e operacional, como empresas intermediadoras de pagamento e provedoras de serviço de armazenamento de informações, (iv) e com empresas patrocinadoras da campanha Restaurateur 5 Anos.',
      '7. PRAZO DE ARMAZENAMENTO DE DADOS',
      'Armazenamos as informações dos Clientes de acordo com as normas de prescrição do Direito brasileiro.', 
      '8. SERVIÇOS DE MARKETING',
      'Os serviços de marketing são oferecidos por meio de comunicações gratuitas sobre ofertas, serviços e produtos dirigidas ao Cliente e ao público interessado em nossas comunicações e eventos, relacionadas ao seu perfil no site e ao serviço contratado. Esse serviço abrange e-mails, SMS e WhatsApp. Vale ressaltar que o Cliente pode cancelar o serviço a qualquer momento e qualquer pessoa pode se retirar de nossa base de contatos através de qualquer e-mail recebido.',
      '9. COOKIES E TECNOLOGIAS SEMELHANTES',
      'Cookies são pequenos arquivos de dados que são colocados no seu computador ou em outros dispositivos (como "smartphones" ou "tablets") enquanto você navega no site.',
      'Utilizamos cookies, pixels e outras tecnologias para reconhecer seu navegador ou dispositivo, aprender mais sobre seus interesses, acompanhar os itens armazenados no seu carrinho de compras, fornecer recursos e serviços essenciais e também para impedir atividades fraudulentas.',
      'As informações eventualmente armazenadas em cookies que permitam a sua identificação são consideradas dados pessoais. Dessa forma, todas as regras previstas nesta Política de Privacidade também lhes são aplicáveis.',
      '10. COMO MANTER SUAS INFORMAÇÕES PESSOAIS SEGURAS',
      'A EGG utiliza os melhores protocolos de segurança para preservar a privacidade dos dados dos Clientes, mas também recomenda medidas de proteção individual.',
      'Para manter a segurança e proteção dos seus dados pessoais fornecidos no cadastro ou nas compras, em nenhuma hipótese o seu login e senha devem ser compartilhados com terceiros. Além disso, ao acessar o seu cadastro, principalmente em computadores públicos, certifique-se de que você realizou o logout da sua conta para evitar que pessoas não autorizadas acessem e utilizem as informações sem o seu conhecimento.',
      'Não solicitamos dados pessoais por telefone, WhatsApp, SMS ou e-mail. Em nenhuma hipótese eles devem ser fornecidos, pois pode ser uma tentativa de uso indevido.',
      '11. LEGISLAÇÃO E FORO',
      'Esta política será regida, interpretada e executada de acordo com as Leis da República Federativa do Brasil, especialmente a Lei nº 13.709/2018, independentemente das Leis de outros estados ou países, sendo competente o foro de domicílio do Cliente para dirimir qualquer dúvida decorrente deste documento.',
      '12. CONTATE-NOS',
      'Você poderá solicitar à EGG, de forma fácil e acessível, através do nosso e-mail atendimento@negociosdagastronomia.com.br: (i) a confirmação da existência de tratamento de dados pessoais; (ii) o acessos aos dados pessoais; (iii) a correção de dados incompletos, inexatos ou desatualizados; (iv) anonimização, bloqueio ou eliminação dos dados desnecessários ou excessivos; (v) portabilidade dos dados, dentro dos limites legais e ainda em caso de dúvidas ou comentários sobre esta Política de Privacidade, além do nosso e-mail, poderá entrar em contato também via telefone +55 (11) 4861-4077/ +55 (11) 94576-1531.',
      'Estamos sempre à disposição.'
    ]
  }),

  methods: {
    // concordoGdpr() {
    //   this.gdpr = !this.gdpr
    // },
  }

}
</script>
