<template>
  <div>
    <navbar/>
    <v-main>
      <capa endtime="endtime"/>
      <entregas/>
      <palestrantes endtime="endtime"/>
      <!--<cta/>-->
      <programacao/>
      <!--<participe/> -->
      <cta-guia/>
      <!-- <sobre/> -->
      <patrocinadores/>
      <apoiadores/>
      <rodape/>
      <lgpd/>
    </v-main>
  </div>
</template>

<script>
  import Navbar from '../components/Navbar'
  import Capa from '../components/Capa'
  import Entregas from '../components/Entregas'
  import Palestrantes from '../components/Palestrantes'
  // import Cta from '../components/Cta'
  import Programacao from '../components/Programacao'
  import CtaGuia from '../components/CtaGuia'
  // import Participe from '../components/Participe'
  // import Sobre from '../components/Sobre'
  import Patrocinadores from '../components/Patrocinadores'
  import Apoiadores from '../components/Apoiadores'
  import Rodape from '../components/Rodape'
  import Lgpd from '../components/Lgpd'

  export default {
    name: 'HomeView',

    components: {
      Navbar,
      Capa,
      Entregas,
      Palestrantes,
      // Cta,
      Programacao,
      CtaGuia,
      // Participe,
      // Sobre,
      Patrocinadores,
      Apoiadores,
      Rodape,
      Lgpd
    },

    data: () => ({
       //endtime: 'Jun 27, 2022 19:00:00',
    })
  }
</script>
