var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"black pa-0",attrs:{"fluid":"","dark":""}},[_c('v-parallax',{attrs:{"src":_vm.getImg(_vm.capa),"height":"100%"}},[_c('div',{staticStyle:{"background-color":"rgba(0,0,0, 0.7)","position":"absolute","top":"0","left":"0","width":"100%","height":"100%"}}),_c('v-row',{staticClass:"py-12 justify-center white--text",staticStyle:{"position":"relative"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"10"}},[_c('div',{staticClass:"pt-4 text-h5",staticStyle:{"line-height":"1"}},[_c('span',{staticClass:"poppins text-uppercase font-weight-medium"},[_vm._v("Curso "),_c('span',{staticClass:"primary--text"},[_vm._v("100%")]),_vm._v(" Gratuito e Online")])]),_c('v-card',{staticClass:"transparent pt-4 text-h1",staticStyle:{"line-height":"1"},attrs:{"tile":"","flat":""}},[_c('v-img',{staticClass:"mx-auto",attrs:{"contain":"","width":"98%","src":_vm.getImg(_vm.restaurateur)}})],1),_c('v-row',{staticClass:"pt-6 align-center"},[_c('v-col',{staticClass:"text-h5"},[_c('div',[_c('span',{staticClass:"raleway white--text text--darken-2 font-weight-regular"},[_vm._v("Você quer ter um "),_c('b',{staticClass:"white--text text--darken-3"},[_vm._v("restaurante de sucesso")]),_vm._v("?")])]),_c('div',{staticClass:"pt-3"},[_c('span',{staticClass:"raleway white--text text--darken-2 font-weight-regular"},[_vm._v("Assista gratuitamente a "),_c('b',{staticClass:"primary--text"},[_vm._v("5 masterclasses")]),_vm._v(" + "),_c('b',{staticClass:"primary--text"},[_vm._v("6 lives")]),_vm._v(" + "),_c('b',{staticClass:"primary--text"},[_vm._v("5 podcasts")]),_vm._v(" especiais com grandes nomes da Nova Gastronomia!")])]),_c('div',{staticClass:"pt-6"},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v("mdi-chevron-double-down")])],1)])],1),_c('v-row',{class:{
              'mt-n10 pb-0': _vm.$vuetify.breakpoint.smAndDown,
              'mt-n16': _vm.$vuetify.breakpoint.mdAndUp,
            }},[_c('v-col',[_c('div',{staticStyle:{"padding":"75% 0 0 0","position":"relative"}},[_c('iframe',{staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"},attrs:{"src":_vm.src,"frameborder":"0","allow":"autoplay; fullscreen; picture-in-picture","allowfullscreen":"","title":"Apresentação Semana Restaurateur 5A"}})])])],1),_c('v-row',{staticClass:"pb-12 justify-center",attrs:{"id":"inscricao"}},[_c('v-col',{staticClass:"white--text",attrs:{"cols":"12","md":"10"}},[_c('v-sheet',{staticClass:"transparent elevation-1 primary",attrs:{"outlined":"","rounded":""}},[_c('v-card',{staticClass:"pb-9 text-center justify-center",class:{
                    'py-3': _vm.$vuetify.breakpoint.smAndDown,
                    'pa-6 ': _vm.$vuetify.breakpoint.mdAndUp,
                  },attrs:{"dark":""}},[_c('v-card-title',{staticClass:"pt-2 pb-0 text-h4 text-center justify-center",staticStyle:{"line-height":"1.2"}},[_c('span',{staticClass:"poppins white--text font-weight-semibold text-uppercase"},[_vm._v("Curso "),_c('span',{staticClass:"primary--text"},[_vm._v("100% Gratuito")]),_vm._v(" para restaurantes")])]),_c('inscricao')],1)],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('iframe',{ref:"iframe",attrs:{"id":"iframeid","width":"100%","height":"450","src":_vm.src,"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }