<template>
  <v-container fluid class="py-16 mb-0 secondary" dark>

    <v-row class="pt-9 justify-center">
      <v-col class="pb-0 text-center">
        <div class="text-h6">
          <span class="poppins white--text font-weight-medium text-uppercase">Realização</span>
        </div>
      </v-col>
    </v-row>

    <v-row class="pt-3 pb-12 justify-center align-center">
      <v-col
        cols="5" md="3"
        v-for="(o, i) in organizadores" :key="i"
        class="pt-0 text-center white--text"
      >
        <v-card 
          flat tile 
          class="transparent"
          :href="o.link"
          target="_blank"
        >
          <v-img
            contain
            :src="getImg(o.logo)"
            max-height="90"
          />
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

export default {
  data: () => ({
    organizadores: [
      {
        logo: 'logo-egg.png',
        link: 'https://eggeduca.com/?utm_campaign=r5a-curso-gratuito&utm_source=r5a-lp-curso-gratuito&utm_medium=form-inscricao-curso-gratuito'
      },
      {
        logo: 'logo-egg-r5a.png',
        link: ''
      },
    ],
  }),

  methods: {
    getImg(i) {
      return require('../assets/img/parceiros/'+i)
    },
  },
}
</script>
