<template>
  <v-container fluid class="py-16 black" dark>

    <v-row class="pt-12 justify-center">
      <v-col cols="12" md="8" class="text-center">
        <div class="text-h4">
          <span class="poppins white--text font-weight-semibold text-uppercase">Palestrantes Confirmados</span>
        </div>
        <div class="pt-3 text-h6">
          <span class="poppins grey--text font-weight-regular">Eles alcançaram o sucesso. E agora estão aqui para mostrar a você o caminho das pedras.</span>
        </div>
      </v-col>
    </v-row>

    <v-row
      class="pt-12 pb-4 px-6 justify-center white--text"
    >
      <v-col 
        cols="12" md="10"
        class="text-center"
      >
        <v-row>
          <v-col
            cols="4" md="3"
            v-for="(p, i) in palestrantes"
            :key="i"
            class="pb-16"
          >
            <v-sheet rounded class="transparent" color="">
              <v-img
                rounded
                :src="getImg(p.foto)"
                style="border-radius: 25px"
              />
            </v-sheet>
            <div class="pt-6 text-h5">
              <span class="raleway primary--text font-weight-semibold">{{ p.nome }}</span>
            </div>
            <div class="pt-2 text-subtitle-1" style="line-height: 1.5">
              <span class="raleway grey--text font-weight-medium">{{ p.bio }}</span>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="12" md="10" class="text-center">
        <div>
          <v-btn 
            block
            rounded
            x-large
            height="70"
            color="primary"
            class="px-6 black--text text-h5"
            style="border-radius: 50px !important; border: 2px solid"
            @click="$vuetify.goTo('#inscricao', { offset: 0 })"
          >
            <span class="font-weight-medium">Faça Sua Inscrição</span>
          </v-btn>
        </div>
        <div class="text-caption pt-2">
          <span class="poppins white--text font-weight-medium">A Jornada é 100% Gratuita!</span>  
        </div>
      </v-col>
    </v-row>

  </v-container>
</template>

<style >

</style>

<script>

export default {

  data: () => ({
    palestrantes: [
      {
        foto: 'olivier-anquier.jpg',
        nome: 'Olivier Anquier',
        bio: 'Chef francês radicado no Brasil, empresário, apresentador de TV e um dos maiores nomes da gastronomia brasileira.',
      }, 
      {
        foto: 'renata-vanzetto.jpg',
        nome: 'Renata Vanzetto',
        bio: 'Chef por trás de restaurantes como Ema, Me Gusta e o recém-inaugurado Mico, seu sétimo negócio.',
      },
      {
        foto: 'ivan-achcar.jpg',
        nome: 'Ivan Achcar',
        bio: 'Chef, Pesquisador, Apresentador, Diretor da EGG Educa, a Escola de Negócios da Nova Gastronomia.',
      },
      {
        foto: 'rubens-oliveira.jpg',
        nome: 'Rubens Oliveira',
        bio: 'Possui mais de 15 anos de experiência no setor, atuando como consultor financeiro e de processos sistêmicos.',
      }, 
      {
        foto: 'marcelo-traldi.jpg',
        nome: 'Marcelo Traldi',
        bio: 'Coordenador do Curso Restaurauter Master, da EGG Educa. É autor do livro "Tecnologias Gerenciais de Restaurantes" e consultor de empresas de alimentação.',
      }, 
      {
        foto: 'andrea-ceneviva.jpg',
        nome: 'Andrea Ceneviva',
        bio: 'Uma das mais requisitadas coaches do mercado, já teve como  coachees centenas de importantes executivos e CEOs.',
      }, 
      {
        foto: 'ciro-aidar.jpg',
        nome: 'Ciro Aidar',
        bio: 'Foi determinante na expansão do Grupo Pobre Juan, que dobrou o número de restaurantes de cinco para 11, em apenas dois anos.',
      },
      // {
      //   foto: 'rodrigo-martins.jpg',
      //   nome: 'Rodrigo Martins',
      //   // bio: 'Já prestou consultoria para mais de 100 empresas no Brasil e no exterior, e também participou em três temporadas do programa MasterChef Brasil.',
      //   bio: 'Já prestou consultoria para mais de 100 empresas no Brasil e no exterior, e também foi consultor como Chef Executivo do programa MasterChef Brasil por três temporadas',
      // },
      // {
      //   foto: 'marcelo-fernandes.jpg',
      //   nome: 'Marcelo Fernandes',
      //   bio: 'Professor pioneiro da EGG Educa, Marcelo Fernandes é um Restaurateur de sucesso em São Paulo, com restaurantes premiados pelo Guia Michelin.',
      // },
      // {
      //   foto: 'a-confirmar.jpg',
      //   nome: '+ Palestrantes a Confirmar',
      //   bio: '',
      // },
      {
        foto: 'n1-bruno-hoffman2.jpg',
        nome: 'Bruno Hoffman',
        bio: 'Restaurateur do Caos Brasilis',
      },
      {
        foto: 'n2-fabricio-lemos2.jpg',
        nome: 'Fabrício Lemos',
        bio: 'Restaurateur do Grupo Origem',
      },
      {
        foto: 'n3-ju-tricate2.jpg',
        nome: 'Ju Tricate',
        bio: 'Restauratrice do De Segunda',
      },
      {
        foto: 'n4-gabriel-coelho2.jpg',
        nome: 'Gabriel Coelho',
        bio: 'Restaurateur do De Segunda',
      },
      {
        foto: 'n5-marcio-blak2.jpg',
        nome: 'Márcio Blak',
        bio: 'Advisor da vertical Food/Spice',
      },
      {
        foto: 'n6-georges-schnyder.jpg',
        nome: 'Georges Schnyder',
        bio: 'Diretor executivo do Mundo MESA e sócio da revista Prazeres da Mesa',
      },
      {
        foto: 'n7-mariella-lazaretti.jpg',
        nome: 'Mariella Lazaretti',
        bio: 'Editora e sócia da revista Prazeres da Mesa',
      },
      {
        foto: 'n8-paulo-bitelman2.jpg',
        nome: 'Paulo Bitelman',
        bio: 'Sócio do Grupo Le Jazz',
      },
      {
        foto: 'n9-nathalia-sifuentes2.jpg',
        nome: 'Nathalia Sifuentes',
        bio: 'Diretora da Muncher BR',
      },
      {
        foto: 'n10-andre-mortari2.jpg',
        nome: 'André Mortari',
        bio: 'Diretor da Let\'s Delivery',
      },
      {
        foto: 'n11-aline-batah.jpg',
        nome: 'Aline Batah',
        bio: 'Head Comercial da Get In',
      },
      {
        foto: 'n12-gabriel-pinheiro.jpg',
        nome: 'Gabriel Pinheiro',
        bio: 'Restaurateur da Pizzaria Villa Roma',
      },
      {
        foto: 'n13-joao-cury.jpg',
        nome: 'João Cury',
        bio: 'Sócio da Topema Innovations',
      },
      {
        foto: 'n14-caroline-lofti.jpg',
        nome: 'Caroline Lofti',
        bio: 'Gerente comercial da Topema Innovations',
      },
      {
        foto: 'n15-marcus-livi2.jpg',
        nome: 'Marcus Livi',
        bio: 'Sócio do Grupo Bah',
      },
      {
        foto: 'n16-nelson-millner.jpg',
        nome: 'Nelson Millner',
        bio: 'Diretor da Altec Sistemas',
      },
      {
        foto: 'n17-monica-rangel2.jpg',
        nome: 'Mônica Rangel',
        bio: 'Sócia da Gosto com Gosto',
      },
      {
        foto: 'n18-paulo-neto2.jpg',
        nome: 'Paulo Neto',
        bio: 'Sócio da Nexxo',
      },
    ],
  }),

  methods: {
    getImg(i) {
      return require('../assets/img/palestrantes/'+i)
    },
  },
}
</script>
