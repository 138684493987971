<template>
  <v-container fluid class="py-16 grey lighten-5" dark>

    <v-row class="pt-12 pb-0 justify-center">
      <v-col class="pb-0 text-center">
        <div class="text-h6">
          <span class="poppins grey--text text--darken-3 font-weight-medium">Nossos</span>
        </div>
        <div class="pt-1 text-h4">
          <span class="poppins black--text text--darken-3 font-weight-semibold text-uppercase">Patrocinadores</span>
        </div>
      </v-col>
    </v-row>

    <v-row class="pb-0 justify-center">
      <v-col cols="12" md="9">
        <v-row class="px-12 pt-9 justify-center">
          <v-col
            cols="6" md="4"
            v-for="(p, i) in patrocinadores" :key="i"
            class="pt-0 text-center white--text"
          >
            <v-card
              flat
              class="py-0 transparent"
              :href="p.link"
              target="_blank"
            >
              <v-img
                contain
                :src="getImg(p.logo)"
                style="opacity: .8"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pb-12 justify-center">
      <v-col cols="6" class="pt-9 text-center white--text">
        <div>
          <div class="pt-3 pb-3 text-h6 black--text">
            <span class="raleway font-weight-bold">Milhares de reais em vouchers oferecidos por nossos parceiros para nossos Alunos EGG!</span>
          </div>
          <v-btn 
            rounded outlined
            height="50"
            class="px-12 mx-2 mb-3 black primary--text text-subtitle-1"
            style="border-radius: 50px !important;"
            :href="vouchers"
            target="_blank"
          >
            <span class="font-weight-semibold">Ver Vouchers</span>
          </v-btn>
          <div class="px-2 pb-3">
          <v-btn 
            rounded outlined
            height="50"
            class="px-12 mx-2 mb-3 primary grey--text text--darken-4 text-subtitle-1"
            style="border-radius: 50px !important;"
            :href="whatsapp"
            target="_blank"
          >
            <span class="font-weight-semibold">Seja um Patrocinador</span>
          </v-btn>
        </div>
        </div>
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>

export default {
  data: () => ({
    whatsapp: 'https://api.whatsapp.com/send/?phone=5511995747234&text=Ol%C3%A1%2C+Fernanda%21+Estou+vindo+do+site+da+Semana+Restaurateur+5+Anos+e+gostaria+de+obter+informa%C3%A7%C3%B5es+sobre+como+ser+um+patrocinador.',
    vouchers: 'https://lp.sourestaurateur.com.br/parceiros/?utm_campaign=curso-gratuito-r5a&utm_source=lp-curso-gratuito-r5a&utm_medium=bloco-patrocinadores',
    patrocinadores: [
      {
        logo: '01_logo_getin_diamante.png',
        //link: 'https://www.getinapp.com.br/?utm_campaign=egg-r5a-curso-gratuito&utm_source=egg-r5a-lp-curso-gratuito&utm_medium=form-inscricao-curso-gratuito'
        link: 'https://lp.getinapp.com.br/gestao-de-fila?utm_campaign=B2B%20-%20Parceria%20Egg&utm_source=email&utm_medium=emkt&utm_content=parceria-egg'
      },
      {
        logo: '03_logo_spice_diamante.png',
        link: 'https://www.spicesoftware.com.br/?utm_campaign=egg-r5a-curso-gratuito&utm_source=egg-r5a-lp-curso-gratuito&utm_medium=form-inscricao-curso-gratuito'
      },
      {
        logo: '02_logo_topema_diamante.png',
        link: 'https://www.topema.com/?utm_campaign=egg-r5a-curso-gratuito&utm_source=egg-r5a-lp-curso-gratuito&utm_medium=form-inscricao-curso-gratuito'
      },
      {
        logo: '04_logo_altec_diamante.png',
        link: 'https://altec.ws/?utm_campaign=egg-r5a-curso-gratuito&utm_source=egg-r5a-lp-curso-gratuito&utm_medium=form-inscricao-curso-gratuito'
      },
      {
        logo: '05_logo_lets-delivery_ouro.png',
        link: 'https://www.lets.delivery/?utm_campaign=egg-r5a-curso-gratuito&utm_source=egg-r5a-lp-curso-gratuito&utm_medium=form-inscricao-curso-gratuito'
      },
      {
        logo: '06_logo_neo-gourmet_prata.png',
        link: 'https://neogourmetarquitetura.com.br/?utm_campaign=egg-r5a-curso-gratuito&utm_source=egg-r5a-lp-curso-gratuito&utm_medium=form-inscricao-curso-gratuito'
      },
    ],
  }),

  methods: {
    getImg(i) {
      return require('../assets/img/parceiros/'+i)
    },
  },
}
</script>
