<template>
  <v-container fluid class="black pa-0" dark>
    <v-parallax
      :src="getImg(capa)"
      height="100%"
    >
      <div style="
          background-color: rgba(0,0,0, 0.7);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
      "></div>
      <v-row 
        class="py-12 justify-center white--text"
        style="position: relative"
      >
        <v-col 
          cols="12" md="10"
          class="text-center"
        >
          <!-- <v-chip
            outlined large
            class="mb-2"
            color="white"
          >
            <div class="text-subtitle-1 px-6" color="">
              <span class="poppins text-uppercase font-weight-bold">27/Jun a 01/Jul</span>
            </div>
          </v-chip> -->

          <div class="pt-4 text-h5 " style="line-height: 1">
            <span class="poppins text-uppercase font-weight-medium">Curso <span class="primary--text">100%</span> Gratuito e Online</span>
          </div>
          
          <v-card tile flat class="transparent pt-4 text-h1" style="line-height: 1">
            <v-img
              contain
              width="98%"
              :src="getImg(restaurateur)"
              class="mx-auto"
            />
          </v-card>

          <v-row class="pt-6 align-center">
            <v-col class="text-h5">
              <div>
                <span class="raleway white--text text--darken-2 font-weight-regular">Você quer ter um <b class="white--text text--darken-3">restaurante de sucesso</b>?</span>
              </div>
              <div class="pt-3">
                <span class="raleway white--text text--darken-2 font-weight-regular">Assista gratuitamente a <b class="primary--text">5 masterclasses</b> + <b class="primary--text">6 lives</b> + <b class="primary--text">5 podcasts</b> especiais com grandes nomes da Nova Gastronomia!</span>
              </div>
              <div class="pt-6">
                <v-icon x-large color="primary">mdi-chevron-double-down</v-icon>
              </div>
            </v-col>
          </v-row>

          <v-row
            class=""
            :class="{
              'mt-n10 pb-0': $vuetify.breakpoint.smAndDown,
              'mt-n16': $vuetify.breakpoint.mdAndUp,
            }"
          >
            <v-col>
              <!-- <v-sheet outlined rounded dark class="elevation-12" color="grey darken-3"> -->
                <!-- <v-card dark transparent > -->
                  <!-- <iframe id="iframeid" ref="iframe" width="100%" height="450" :src="src" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                  <div style="padding:75% 0 0 0;position:relative;">
                    <iframe :src="src" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Apresentação Semana Restaurateur 5A"></iframe>
                  </div>
                  <!-- <v-img
                    :src="getImg(capaVideo)"
                    @click.stop="abreModal(vid)"
                    style="cursor: pointer;"
                    class=""
                  >
                    <v-row class="fill-height align-center justify-center">
                      <v-col>
                        <v-btn text class="" style="opacity:.5">
                          <v-icon size="90" class="white--text">mdi-play</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-img> -->
                <!-- </v-card> -->
              <!-- </v-sheet> -->
            </v-col>
          </v-row>
          
          <v-row id="inscricao" class="pb-12 justify-center">
            <!-- <v-col cols="12" class="align-center text-center">
              <contador/>
            </v-col> -->
            <v-col cols="12" md="10" class="white--text">
              <v-sheet outlined rounded class="transparent elevation-1 primary">
                <v-card dark 
                  class="pb-9 text-center justify-center"
                  :class="{
                    'py-3': $vuetify.breakpoint.smAndDown,
                    'pa-6 ': $vuetify.breakpoint.mdAndUp,
                  }"
                >
                  <!-- <v-card-title class="pb-0 text-h6 text-center justify-center" style="line-height: 1.2">
                    <span class="poppins white--text font-weight-regular">As vagas vão acabar. Garanta logo a sua!</span>
                  </v-card-title> -->
                  <v-card-title class="pt-2 pb-0 text-h4 text-center justify-center" style="line-height: 1.2">
                    <span class="poppins white--text font-weight-semibold text-uppercase">Curso <span class="primary--text">100% Gratuito</span> para restaurantes</span>
                  </v-card-title>
                  <!-- <div class="_form_5"></div> -->
                  <inscricao/>
                </v-card>
              </v-sheet>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
    </v-parallax>
    
    

    <v-dialog
      v-model="dialog"
      max-width="800"
    >
      <iframe v-if="dialog" id="iframeid" ref="iframe" width="100%" height="450" :src="src" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </v-dialog>

  </v-container>
</template>

<style >
/* .heightParallax{
  height: 100vh !important
} */
</style>

<script>
import Contador from '../components/Contador'
import Inscricao from '../components/Inscricao'

export default {
  components: {
    Contador,
    Inscricao
  },

  //props: ['endtime'],

  data: () => ({
    restaurateur: 'restaurateur.svg',
    capa: 'bg-capa.jpg',
    dialog: false,
    vid: '729957972?h=04df9d9108&amp;badge=0&amp;autopause=0&amp;player_id=0&amp',
    capaVideo: 'capa-video.jpg',
    vidSelecionado: '',
    atributosAdicionais: '&autoplay=0&enablejsapi=1',
    
  }),
  computed: {
    src: {
      get: function () {
        //return 'https://player.vimeo.com/video/' + this.vidSelecionado + this.atributosAdicionais
        //https://player.vimeo.com/video/719609011?h=e2e311b5fd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479
        return 'https://player.vimeo.com/video/' + this.vid + this.atributosAdicionais
      }
    }
  },
  methods: {
    
    abreModal(v) {
      this.dialog = true
      this.vidSelecionado = v
      //this.$refs.iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
    },

    getImg(i) {
      return require('../assets/img/'+i)
    },
  },

  mounted() {
    // CARREGANDO FOR ACTIVE CAMPAIGN
    // let externalScript = document.createElement('script')
    // externalScript.setAttribute('src', 'https://eggeduca.activehosted.com/f/embed.php?id=5')
    // document.head.appendChild(externalScript)
  }
}
</script>
