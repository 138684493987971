<template>
  <v-container fluid class="pt-9" dark>
      
    <v-row id="inscricao" class="px-2 pb-0 justify-center">
      <v-col cols="12" md="10" class="white--text">
        <v-form 
          ref="form" 
          id="form" 
          class="text-center" 
          v-model="valid"
          action='https://eggeduca.activehosted.com/proc.php'
          method="POST"
          @submit="submit"
        >
          <!-- <input type="hidden" name="u" value="5" />
          <input type="hidden" name="f" value="5" />
          <input type="hidden" name="s" />
          <input type="hidden" name="c" value="0" />
          <input type="hidden" name="m" value="0" />
          <input type="hidden" name="act" value="sub" />
          <input type="hidden" name="v" value="2" />
          <input type="hidden" name="or" value="c42191210c58e0a3eb566b4a838909cb" /> -->
          <input type="hidden" name="u" v-model="form.u" />
          <input type="hidden" name="f" v-model="form.u" />
          <input type="hidden" name="s" />
          <input type="hidden" name="c" v-model="form.c" />
          <input type="hidden" name="m" v-model="form.m" />
          <input type="hidden" name="act" v-model="form.act" />
          <input type="hidden" name="v" v-model="form.v" />
          <input type="hidden" name="or" v-model="form.or" />
          <input type="hidden" name="field[62]" v-model="form.utm_campaign" />
          <input type="hidden" name="field[63]" v-model="form.utm_source" />
          <input type="hidden" name="field[64]" v-model="form.utm_medium" />
          <input type="hidden" name="field[65]" v-model="form.utm_content" />
          <input type="hidden" name="field[66]" v-model="form.utm_term" />
          <input type="hidden" name="field[72]" v-model="form.fbclid" />
          <input type="hidden" name="field[71]" v-model="form.gclid" />
          <!-- <input type="hidden" name="form_interesse" v-model="form.form_interesse" /> -->

          <v-row>
            <v-col cols="12" class="py-0 my-0"> 
              <v-text-field
                filled
                id="firstname"
                name="firstname"
                v-model="form.nome"
                :rules="form.nomeRules"
                label="Primeiro Nome"
                color=""
                class="py-0 my-0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0 my-0"> 
              <v-text-field
                filled
                id="email"
                name="email"
                v-model="form.email"
                :rules="form.emailRules"
                label="E-mail"
                color=""
                class="py-0 my-0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0 my-0"> 
              <v-text-field
                filled
                id="phone"
                name="phone"
                v-model="form.whatsapp"
                :rules="form.whatsappRules"
                label="WhatsApp"
                v-mask="mask"
                color=""
                class="py-0 my-0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0 my-0 text-left"> 
              <!-- <v-text-field
                filled
                
                
                :rules="form.field77Rules"
                label="Qual é sua relação com restaurantes?"
                color=""
                class="py-0 my-0"
              ></v-text-field> -->
              <p>Qual é sua relação com restaurantes?</p>
              <v-radio-group
                v-model="form.field77"
                id="field[77]"
                name="field[77]"
                
              >
                <v-radio
                  label="Sou dono de restaurante"
                  value="Sou dono de restaurante"
                ></v-radio>
                <v-radio
                  label="Tenho interesse em abrir um restaurante"
                  value="Tenho interesse em abrir um restaurante"
                ></v-radio>
                <v-radio
                  label="Trabalho em um restaurante"
                  value="Trabalho em um restaurante"
                ></v-radio>
                <v-radio
                  label="Outro"
                  value="Outro"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn 
                id="submit"
                ref="submitButton"
                x-large rounded block
                color="primary black--text"
                class="py-0 my-0 text-h5"
                type="submit"
                :loading="loading" :disabled="loading"
              >
                <span class="font-weight-bold">Fazer Inscrição</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import axios from 'axios'
import {mask} from 'vue-the-mask'
let qs = require('qs')

export default {
  directives: {mask},
  data: () => ({
    loading: false,
    valid: false,
    mask: '(##) #####-####',
    form: {
      utm_campaign: '',
      utm_source: '',
      utm_medium: '',
      utm_content: '',
      utm_term: '',
      fbclid: '',
      gclid: '',
      //form_interesse: 'r5a-novo',
      u: '20',
      f: '20',
      s: '',
      c: '0',
      m: '0',
      act: 'sub',
      v: '2',
      or: '803b98ea422bd36b8cf4a3c1b8003e5f',
      //nome: 'André TESTE 40',
      field77: '',
      nome: '',
      nomeRules: [
        (v) => !!v || 'Informe seu nome'
      ],
      //email: 'email@andreortega.com',
      email: '',
      emailRules: [
        (v) => !!v || 'Informe seu e-mail',
        //v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Informe um e-mail válido'
      ],
      //whatsapp: '(61) 99290-5080',
      whatsapp: '',
      whatsappRules: [
        (v) => !!v || 'Informe seu whatsapp'
      ],
    }
  }),
  methods: {
    submit(e) {
     // e.preventDefault()
      var vm = this
      if (this.$refs.form.validate()) {
        vm.loading = true
        //alert('validou')
        console.log(vm.$refs.submitButton)
        //vm.$refs['form'].submit()
        vm.loading = false
        vm.$refs.submitButton.click()
        
        //var vm = this
  //       let params = qs.stringify({
  //         form: vm.form
  // //            form: $("#form_inscricao").serialize()
  //       })
  // //          console.log(params)
        // axios.post('https://eggeduca.activehosted.com/proc.php', params)
        //   .then(response => {
        //     console.log('AXIOS NEXT', response.data)
        //   }).catch(e => {
        //     console.log('AXIOS CATCH', e)
        //   })
      }
      else {
        console.log('não validou')
      }
    }
  },
  mounted: function() {
    this.form.utm_campaign   = this.$route.query.utm_campaign
    this.form.utm_source     = this.$route.query.utm_source
    this.form.utm_medium     = this.$route.query.utm_medium
    this.form.utm_content    = this.$route.query.utm_content
    this.form.utm_term       = this.$route.query.utm_term
    this.form.fbclid         = this.$route.query.fbclid
    this.form.gclid          = this.$route.query.gclid
    console.log('FORM MOUNTED',this.form)
  },
}
</script>
